





























































































import { LastMessage } from '@/components/ChatsBar/types'
import store, { contactsStore, uiSettingsStore } from '@/store'
import { CallStatus } from '@/store/modules/root/types'
import { displayedDate, parseDraft } from '@/utils'
import { Chat, JID } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Badges: () => import('@/components/ChatsBar/List/Item/Badges.vue'),
    BaseAvatar: () => import('@/components/UI/BaseAvatar.vue'),
    Icons: () => import('@/components/ChatsBar/List/Item/Icons.vue'),
    Menu: () => import('@/components/ChatsBar/List/Item/Menu.vue'),
  },
})
export default class Full extends Vue {
  @Prop({ type: Object, required: true }) private readonly chat!: Chat
  @Prop({ type: Boolean }) private readonly isAfk!: boolean
  @Prop({ type: Boolean }) private readonly isMobile!: boolean
  @Prop({ type: Boolean }) private readonly isOnline!: boolean
  @Prop({ type: Boolean }) private readonly isMuted!: boolean
  @Prop({ type: Object }) private readonly lastMessage?: LastMessage
  @Prop({ type: String }) private readonly callStatus?: CallStatus

  @Prop({ type: Boolean }) private readonly showHide?: boolean
  @Prop({ type: Boolean }) private readonly showPin?: boolean
  @Prop({ type: Boolean }) private readonly showSystembot?: boolean
  @Prop({ type: Boolean }) private readonly systembotNotifications?: boolean

  private get composing () {
    const ids: JID[] = store.getters.chatComposingIds(this.chat.jid)
    if (!ids.length) return

    const { isThemeNY } = uiSettingsStore.getters

    const getText = (isAudio = false, isPlural = false) => {
      if (isPlural) {
        return isAudio
          ? this.$t('chattape.userRecAudioMsgShort_plural').toString()
          : isThemeNY
            ? this.$t('chatlist.writingNY_plural').toString()
            : this.$t('chatlist.writing_plural').toString()
      } else {
        return isAudio
          ? this.$t('chattape.userRecAudioMsgShort').toString()
          : isThemeNY
            ? this.$t('chatlist.writingNY').toString()
            : this.$t('chatlist.writing').toString()
      }
    }

    const audioIds: JID[] = store.getters.chatAudioComposingIds(this.chat.jid)
    if (this.chat.chatType !== 'group') return getText(!!audioIds.length)

    let prefix = ''
    let suffix = ''

    if (ids.length === 1) {
      const contact = contactsStore.getters.contact(ids[0])
      prefix = contact?.shortName ?? this.$tc('glossary.man', 1)
      suffix = getText(!!audioIds.length)
    } else {
      const equal = (
        ids.length === audioIds.length &&
        !ids.some(id => !audioIds.includes(id))
      )

      prefix = `${ids.length} ${this.$tc('glossary.man', ids.length)}`
      suffix = getText(equal, true)
    }

    return `${prefix} ${suffix}`
  }

  private get draftText () {
    if (!window.FEATURES.server_drafts || !this.chat.draft) return
    return parseDraft(this.chat.draft)
  }

  private get date () {
    if (!this.chat.lastMessage) return
    return displayedDate(new Date(this.chat.lastMessage.created))
  }
}
